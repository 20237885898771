<template>
    <div class="Viewteacherreport" style="margin-top:20px">
        <div class="Project_icon">
            <el-tooltip content="返回上一层" placement="top-start">
                <i class="iconfont icon-fanhui" @click="returnTo()"></i>
            </el-tooltip>
        </div>
        <div class="BasicProject clearfix">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm" :inline="true">
                <p class="form_title">
                    <span>
                        基本信息：
                    </span>
                </p>
                <div class="declare_form_one clearfix">
                    <el-col
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="项目编号" prop="name" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="项目名称" prop="name" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col 
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="项目类型" prop="type" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col 
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="项目级别" prop="type" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col 
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="项目负责人" prop="type" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col 
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="项目开始时间" prop="declarationTime" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col 
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="项目结束时间" prop="declarationTime" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col 
                        :xl="{span:6}"
                        :lg="{span:8}"
                        :md="{span:12}">
                        <el-form-item label="填表时间" prop="declarationTime" style="width:100%">
                            <el-input v-model="ruleForm.name" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </div>
            </el-form>
            <p class="form_title fl">
                <span>
                    团队成员：
                </span>
            </p>
            <div class="teammem fl">
                <el-table 
                    :data="tableData" 
                    style="width: 100%;min-height:150px"
                    stripe
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#fafafa',color:'rgba(0,0,0,.85)','text-align':'center'}"
                >   
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="date" label="年级"></el-table-column>
                    <el-table-column prop="name" label="学校"></el-table-column>
                    <el-table-column prop="date" label="所在院系/专业"></el-table-column>
                    <el-table-column prop="date" label="联系电话"></el-table-column>
                    <el-table-column prop="date" label="E-mail"></el-table-column>
                </el-table>
            </div>
            <p class="form_title fl">
                <span>
                    指导教师：
                </span>
            </p>
            <div class="teammem fl">
                <el-table 
                    :data="teachList" 
                    style="width: 100%;min-height:150px"
                    stripe
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#fafafa',color:'rgba(0,0,0,.85)','text-align':'center'}"
                >   
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="age" label="年龄"></el-table-column>
                    <el-table-column prop="phone" label="联系电话"></el-table-column>
                    <el-table-column prop="researchOrientation" label="研究方向"></el-table-column>
                    <el-table-column prop="executivePosition" label="行政职务/专业技术职务"></el-table-column>
                    <el-table-column prop="outcome" label="主要成果"></el-table-column>
                </el-table>
            </div>
            <p class="form_title">
                <span>
                    项目总结报告：
                </span>
            </p>
            <div class="SummaryReport clearfix">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm" :inline="true">
                    <el-col :span="11">
                        <el-form-item prop="purpose" label="一、项目的意义及前景" style="width:100%">
                            <el-input type="textarea" :rows="8" disabled v-model="ruleForm.purpose"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                        <div style="height:10px">

                        </div>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item prop="keyproblem" label="二、项目研究内容和拟解决的关键问题" style="width:100%">
                            <el-input type="textarea" :rows="8" disabled v-model="ruleForm.keyproblem"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item prop="Basicitions" label="三、项目的进度安排" style="width:100%">
                            <el-input type="textarea" :rows="8" disabled v-model="ruleForm.Basicitions"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                        <div style="height:10px">

                        </div>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item prop="Impleplan" label="四、项目总结" style="width:100%">
                            <el-input type="textarea" :rows="8" disabled v-model="ruleForm.Impleplan"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </div>
            <p class="form_title">
                <span>
                    经费使用情况：
                </span>
            </p>
             <div class="Useoffunds">
                <el-table 
                    :data="tableData" 
                    style="width: 100%;min-height:200px"
                    stripe
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#fafafa',color:'rgba(0,0,0,.85)','text-align':'center'}"
                >   
                    <el-table-column type="index" width="100" label="#"></el-table-column>
                    <el-table-column prop="name" label="名称"></el-table-column>
                    <el-table-column prop="money" label="金额"></el-table-column>
                    <el-table-column prop="bill" label="票据">
                        <template slot-scope="scope">
                            <img :src="scope.row.bill" alt="" width="100" height="80">
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ruleForm:{},
            tableData:[],
            teachList:[],
        }
    },
    methods: {
        returnTo(){
            this.$router.push('/Teacherreport')
        },
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Postprojectreport.css';
</style>
<style>
.BasicProject .el-date-editor.el-input{
    width:100%;
}
.BasicProject .declare_form_one .el-form-item__content{
    width: calc(100% - 120px);
}
.SummaryReport .el-form-item__content{
    width: 100%;
}
.SummaryReport .el-form-item__label{
    width: 100%!important;
    text-align: left!important;
}
.SummaryReport .el-textarea__inner{
    resize: none;
}
.SummaryReport .el-form-item__label{
    font-size: 15px;
    font-weight: bold;
}
</style>